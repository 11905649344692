import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  Content,
  OutboundLink,
  FadeReveal,
  multipleComponentIterator,
  FullImageCard,
  InternalLink,
  FishermanIcon,
  MarkdownContent,
  BasicCarousel,
  componentIterator,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  SocialMediaV2,
  ReviewV2,
  CustomerReviewModal,
  HorizontalImageCard,
  BlogPostLink,
  FormattedDate,
  ExpandableText,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withNamedArgs,
  getBusinessFiles,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createTeamMemberSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessFile,
      allFishermanBusinessTeam,
      allFishermanBusinessLocation,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Image
            src={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
            className={"image hero image-overlay"}
            background={true}
          >
            <Grid
              className={"home-section-container"}
              stackable={true}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <Header
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_section_header",
                    defaultValue: "Home",
                  })}
                />
                <Content textAlign={"center"}>
                  <Content.Markup
                    width={8}
                    isMarkdown={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_section_description",
                    })}
                    extra={
                      <div className={"ctas-container"}>
                        <Button
                          primary={true}
                          as={OutboundLink}
                          to={bookingUrl.url}
                          event={{
                            category: "Booking",
                            action: "Schedule Appointment Intent",
                          }}
                        >
                          {bookingUrl.title}
                        </Button>
                      </div>
                    }
                  />
                </Content>
              </Grid.Column>
            </Grid>
          </Image>
          <div className={"services-section"}>
            <Card.Group className={"services"} itemsPerRow={3} stackable={true}>
              <FadeReveal
                cascade={true}
                cascadeFactor={0.4}
                triggerOnce={true}
                className={"ui card"}
                disableAnimationOnMobile={true}
                noContainer={true}
                children={multipleComponentIterator({
                  iterator: allFishermanBusinessMenuCategory.nodes,
                  limit: 3,
                  components: [
                    {
                      component: (
                        <FullImageCard
                          link={true}
                          as={InternalLink}
                          event={{
                            category: "Services",
                            action: "View Category Details",
                          }}
                        />
                      ),
                      propMap: {
                        key: "_id",
                        image: withNamedArgs({
                          func: getBusinessFiles,
                          args: {
                            businessFiles: allFishermanBusinessFile.nodes,
                            numToSelect: 1,
                          },
                        }),
                        "event.label": "name",
                        to: createServiceCategorySlug,
                      },
                      children: [
                        {
                          component: <Card.Header />,
                          propMap: { content: "name", key: "name" },
                        },
                        {
                          component: <FishermanIcon iconName={"arrow-right"} />,
                        },
                      ],
                    },
                  ],
                })}
              />
            </Card.Group>
            <Button
              primary={true}
              to={"/services/"}
              event={{ category: "Services", action: "View All Services" }}
              as={InternalLink}
            >
              See All Services
            </Button>
          </div>
          <div className={"about-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={8} className={"markdown-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "about_section_description",
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8} className={"image-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Image
                    className={"image"}
                    src={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "Content.Image",
                      componentIdentifier: "about_section_image",
                      numToSelect: 1,
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"trusted-by-section"}>
            <Grid stackable={true} columns={2}>
              <Grid.Column width={5} className={"copy-column"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "trusted_by_section_header",
                    defaultValue: "TRUSTED BY:",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={11} className={"images-column"}>
                <BasicCarousel
                  className={"image-gallery"}
                  size={4}
                  displayIndicators={false}
                  displayPlayPauseButton={true}
                  autoScroll={true}
                  animation={{
                    name: "blur",
                    duration: 200,
                    transitionProps: {
                      preAnimating: { opacity: 0.99, blur: "2px" },
                      animating: { opacity: 0.99, blur: "2px" },
                    },
                  }}
                  mobile={{ size: 2 }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                      componentIdentifier: "trusted_by_image_gallery",
                    }),
                    component: (
                      <Image
                        background={true}
                        className={"gallery-image-item"}
                      />
                    ),
                    propMap: { src: "__all__" },
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"stylists-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_header",
                defaultValue: "OUR TOP STYLISTS",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "staff_section_description",
              })}
            />
            <Card.Group className={"featured-stylists"} itemsPerRow={3}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                limit={3}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/team/"}
              primary={true}
              event={{ category: "Team", action: "View All Team Members" }}
              as={InternalLink}
            >
              See All Stylists
            </Button>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_header",
                defaultValue: "WE'RE SOCIAL",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_section_description",
              })}
            />
            <SocialMediaV2>
              <MultipleComponentIterator
                iterator={fishermanBusiness.socialMedia}
                components={[
                  {
                    component: <SocialMediaV2.Platform />,
                    propMap: { key: "_id", url: "link", platform: "type" },
                    children: [
                      {
                        component: <SocialMediaV2.Icon />,
                        propMap: { icon: "type" },
                      },
                      {
                        component: <SocialMediaV2.Handle />,
                        propMap: { link: "link" },
                      },
                    ],
                  },
                ]}
              />
            </SocialMediaV2>
            <div
              className={"elfsight-app-fad75de8-721e-403c-a571-60da9ecf79d0"}
              data-elfsight-app-lazy={true}
            />
          </div>
          <div className={"reviews-section"}>
            <BasicCarousel
              size={3}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      { component: <FishermanIcon iconName={"quote-left"} /> },
                      {
                        component: <ReviewV2.Text charLimit={360} />,
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
              />
            </div>
          </div>
          <div className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "LATEST ARTICLES",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group
              className={"featured-blogs"}
              itemsPerRow={2}
              stackable={true}
            >
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <HorizontalImageCard imagePosition={"left"} />,
                    propMap: { key: "_id", image: "gatsbyHeroImage" },
                    children: [
                      {
                        component: <BlogPostLink />,
                        propMap: { key: "_id", slug: "slug", title: "title" },
                        children: [
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <ExpandableText
                                charLimit={160}
                                disableExpanding={true}
                              />
                            ),
                            propMap: { text: "summary" },
                          },
                          {
                            component: (
                              <FishermanIcon iconName={"arrow-right"} />
                            ),
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
            <Button
              to={"/blog/"}
              primary={true}
              as={InternalLink}
              event={{ category: "Blog", action: "View All Blog Posts" }}
            >
              See All Articles
            </Button>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        _id
        link
        type
      }
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        files
      }
    }
    allFishermanBusinessFile {
      nodes {
        _id
        file
        description
        gatsbyFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
